.body {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.header {
    display: flex;
    justify-content: end;
    height: 60px;
}

.plusIcon {
    padding: 10px;
}