.body {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.formFilter {
  display: flex;
  margin-left: 10px;
  margin-bottom: 10px;

  .datesBtn {
    width: 410px;
    margin-right: 5px;
  }
}

.plusIcon {
  size: 40px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 5px;
}
