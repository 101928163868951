.logout {
    display: flex;
    flex-direction: start;
    justify-content: start;
    align-items: start;
    margin: 50px;

    button {
        margin: 10px;
        width: 100px;
        height: 35px;
    }
}