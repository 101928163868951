.container {
    background: lightgray;
    height:100vh;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    max-height: 270px;
    background: white;
    border-radius: 1cm;
    box-shadow: 2ex;

    input {
        padding: 5px;
        margin: 5px 15px;
    }

    button {
        margin: 20px;
        width: 100px;
        height: 35px;
    }
}

